import React,{useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import GA4React from 'ga-4-react';
import './App.css';
import Accordion from './components/Accordion/accordion';
import DisclaimerPage from './components/FooterPages';
import { homeObjTwo } from './components/InfoSection/data';
import Home from './pages';
import aboutUs from './pages/aboutUs';
import disclaimer from './pages/disclaimer';

function App() 
{

 
  
    const ga4react = new GA4React('G-9VBNGKGY63');
    ga4react.initialize().then().catch()

  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/aboutUs' component={aboutUs} exact />
        <Route path='/disclaimer' component={disclaimer} exact />
        <Route path='/faq' component={Accordion} exact />
      </Switch>
    </Router>
    
  );
}

export default App;
