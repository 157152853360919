import React from 'react'
import AboutUsPage from '../components/FooterPages/AboutUs'

const aboutUs = () => {
    return (
        <>
        <AboutUsPage />
        </>
    )
}

export default aboutUs
