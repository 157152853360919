import React from 'react';
import { Container, FormWrap, Icon, FormContent, FormH1, Text } from './aboutuselements'

const AboutUsPage = () => {
    return (
        <>
          <Container>
          <FormWrap> 
          <Icon to="/">Verticality</Icon>
          <FormContent>
              <FormH1>Disclaimer</FormH1>
              <Text>Verticality or VRT is a cryptocurrency founded by Nish Barot in 2022. VRT is a solo project owned and operated by Nish. The expansion of the internet has upstarted a wide array of technologies, and VRT is the newest addition to the crypto world. VRT is a token that can be purchased on PancakeSwap. Verticality is not affiliated with PancakeSwap, MetaMask, or Binance.  </Text>
          </FormContent>
          </FormWrap>  
          </Container>  
        </>
    )
}

export default AboutUsPage