import React,{useState} from 'react';
import {Data} from './faqdata';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
import { FiPlus, FiMinus} from 'react-icons/fi'
import { AccordionSection, Container, Wrap, Dropdown} from './accordionelements';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from 'react-scroll';


const Accordion = () => {
    const [clicked, setClicked] = useState(false)
    const toggle = index => {
        if(clicked == index)
        {
            return setClicked(null)
        }
        setClicked(index)
    }
    return (
        <IconContext.Provider value={{color : '#00FFB9', size: '25px'}}>
        <AccordionSection id='/faq'>  
        <Container>
            {Data.map((item, index) => {
               return (
                   <>
                   <Wrap onClick={()=> toggle(index)} key={index}>
                   <h1>{item.question}</h1>
                   <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                   

                   </Wrap>
                  {clicked === index ? (
                    <Dropdown>
                   <p>{item.answer}</p>

                    </Dropdown>
                  ) : null}
                  
                   </>
               ) 
            })}
        </Container>
        </AccordionSection>
        </IconContext.Provider>

        )
}
export default Accordion
