import React, {useState} from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Sidebar from '../components/Topbar';
import InfoSection from '../components/InfoSection';
import { homeObjOne, homeObjTwo, homeObjThree } from '../components/InfoSection/data';
import Footer from '../components/Footer';
import Accordion from '../components/Accordion/accordion';
const Home = () => {
    const[isOpen, setIsOpen] = useState(false)
    
    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
         <Sidebar  isOpen={isOpen} toggle={toggle}/>   
         <Navbar toggle={toggle} />
         <HeroSection />
         <InfoSection {...homeObjOne}/>
         <InfoSection {...homeObjTwo} />
        <Accordion/>
        <InfoSection {...homeObjThree}/>
        <Footer/>
        </>
    )
}

export default Home
