import React from 'react'
//import { Header, Paragraph } from './DisclaimerElements';
import DisclaimerPage from '../components/FooterPages'
export const disclaimer = () => {
    return (
    <>
    <DisclaimerPage/>
    </>  
    )
}

export default disclaimer
