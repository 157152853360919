import React from 'react';
import { Link } from 'react-scroll';
import Accordion from '../Accordion/accordion';

import { SidebarContainer, 
        Icon, 
        CloseIcon,
        SidebarWrapper, 
        SidebarMenu,
        SidebarLink, 
        SideBtnWrap,
        SidebarRoute,
        ExternalSidebarRoute
    } from './topbarelement';

const Sidebar = ({isOpen, toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
          <Icon onClick={toggle}>
              <CloseIcon />
          </Icon>  
          <SidebarWrapper> 
              <SidebarMenu> 
                  <SidebarLink to="about" onClick={toggle}> 
                    About
                  </SidebarLink>
                  <SidebarLink to="start" onClick={toggle}> 
                    Get Started
                  </SidebarLink>
                  <SidebarLink to='/faq' onClick={toggle}> 
                    FAQ
                  </SidebarLink>
                  <SidebarLink to="nft" onClick={toggle}> 
                    NFTs
                  </SidebarLink>
              </SidebarMenu>
              <SideBtnWrap>
                  <ExternalSidebarRoute href='https://pancakeswap.finance/swap' target='_blank'
          rel="noopener" onClick={toggle}> Purchase</ExternalSidebarRoute>
              </SideBtnWrap>
          </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
