import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import {Nav, ExternalButton, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks, NavBtnLink, NavBtn } from './navbarelements';
import { IconContext } from 'react-icons/lib';
import Accordion from '../Accordion/accordion';
import { animateScroll as scroll } from 'react-scroll';

const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = ()=>{
        if(window.scrollY >= 80)
        {
            setScrollNav(true)
        }
        else
        {
            setScrollNav(false)
        }
    }
    useEffect(()=> {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const toggleFAQ = () => {
        Accordion.scroll();
    }
    return (
      <>
      <IconContext.Provider value={{color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
          <NavbarContainer>
              <NavLogo to='/' onClick={toggleHome}>Verticality</NavLogo>
              <MobileIcon onClick={toggle}>
                  <FaBars />
              </MobileIcon>
              <NavMenu>
                  <NavItem>
                      <NavLinks to="about"
                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                      >About</NavLinks>
                  </NavItem>
                  <NavItem>
                      <NavLinks to="start"
                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                      >Get Started</NavLinks>
                  </NavItem>
                  <NavItem >
                      <NavLinks to='/faq' 
                      smooth={true} duration={500} spy={true} exact='true' offset={-80}
                      >FAQ</NavLinks>
                  </NavItem>
                  <NavItem>
                      <NavLinks to="nft"
                      smooth={true} duration={500} spy={true} exact='true' offset={-80}>NFTs</NavLinks>
                  </NavItem>
              </NavMenu>
              <NavBtn>
                  <ExternalButton href="https://pancakeswap.finance/swap" target="_blank" rel="noopener"> Purchase</ExternalButton> 
              </NavBtn>
          </NavbarContainer>
      </Nav>
      </IconContext.Provider> 
      </> 
    );
};

export default Navbar
