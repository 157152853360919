import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const FooterContainer = styled.footer`
background-color: #101522;
`

export const FooterWrap = styled.div`
padding: 5px 24px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
max-width: 1100px;
margin: 0 auto; 
`

export const FooterLinksContainer = styled.div`
display: flex;
justify-content: center;

@media screen and (max-width: 820px)
{
    padding-top: 32px;
}
`

export const FooterLinksWrapper = styled.div`
display: flex;

@media screen and (max-width: 820px)
{
    flex-direction: column;
}
`

export const FooterLinkItems = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start; 
margin: 16px;
text-align: left; 
width: 160px;
box-sizing: border-box; 
color: #fff;

@media screen and (max-width: 420px)
{
    justify-content: center;
    margin: 10px;
    padding: 10px;
    width: 100%;
}
`
export const FooterLinkTitle = styled.h1`
font-size: 14px;
margin-bottom: 16px;
`

export const FooterLink = styled(Link)`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;

&:hover{
    color: #01bf71;
    transition: 0.3s ease-out;
}
`

export const FooterLinkA = styled.a`
color: #fff;
text-decoration: none;
margin-bottom: 0.5rem;
font-size: 14px;

&:hover{
    color: #01bf71;
    transition: 0.3s ease-out;
}
`
export const SocialMediaWrap = styled.div`
display: flex;
justify-content: space-between;
padding-right: -10px;
align-items: center;
max-width: 1100px;
margin: -5px auto 0 auto;

@media screen and (max-width: 820px)
{
    flex-direction: column;
}
`
export const WebsiteRights = styled.small`

color: #fff;
margin-bottom: 20px;
justify-content: center;
`




