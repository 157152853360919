export const Data = [
{
    question: 'What is Verticality?',
    answer: 'Verticality (VRT) is an ERC-20 token running on the Ethereum network. ERC-20 tokens are blockchain-based assets that have value and can be received and sent.',
},
{
    question: 'Contract Address? (Important!)',
    answer: 'When purchasing change option on PancakeSwap from CAKE by inserting the address: 0x3b977e0f5a28bcdcc4d398a8ca4ba6bcb7590566',
    id: "No School Monday",
    
},

{
    question: 'Who Founded Verticality?',
    answer: 'Nish Barot founded Verticality in 2022.',
},


{
    question: 'Is There Liquidity?',
    answer: 'Yes, VRT was founded on the basic principles of reliability and exchangeability to ensure that liquidity is present with VRT.',
},
{
    question: 'Is Verticality Available On Multiple Exchanges?',
    answer: 'No, as of now, VRT is only available on PancakeSwap.',
},
{
    question: 'How to Purchase?',
    answer: 'It would be best to have a wallet such as Trust Wallet or MetaMask. To purchase VRT, you must buy BNB from Binance and then exchange BNB for VRT on PancakeSwap.',
},
{
    question: 'What Are NFTs?',
    answer: 'A non-fungible token or NFT are a unique digital and non-interchangeable unit of data stored in a blockchain. NFTs can include but are not limited to pictures, videos, and audio. Verticality strives to bring NFTs to our platform soon.',
},


];