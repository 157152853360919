import styled from "styled-components";
import {Link} from 'react-router-dom';

export const Container = styled.div`
min-height: 692px;
position: fixed;
bottom: 0;
left: 0;
right: 0;
top: 0;
z-index: 0;
overflow: hidden;
background: linear-gradient(108deg, rgba(1, 147, 86, 1) 0%,
rgba(10,201,122,1) 100% 
);
`

export const FormWrap = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
height: 400px;

@media only screen and (max-width: 480px), only screen and (max-device-width: 480px) 
{
    height: 550px;
}
`

export const Icon = styled(Link)`
margin-left: 32px;
margin-top: 32px;
text-decoration: none;
color: #fff;
font-weight: 700;
font-size: 32px;

@media screen and (max-width: 480px)
{
    margin-left: 16px;
    margin-top: 8px;
}
`

export const FormContent = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;

@media screen and (max-width: 480px)
{
    padding: 10px;
}
`

export const FormH1 = styled.div`
margin-bottom: 40px;
color: #fff;
font-size: 22px;
font-weight: 400;
text-align: center;
`

export const Text = styled.div`
text-align: center;
margin-top: 24px;
color: #fff;
font-size: 14px;
 word-spacing: 2px;
 font-size: 18px;
@media screen and (max-width: 700px)
{
    font-size: 12px;
    word-spacing: 2px;
} 
`