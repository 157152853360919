import styled from 'styled-components';

export const AccordionSection = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
position: relative;
height: 860px; 

background: #272727; //change later!
`
export const Container = styled.div`
top: 0;
padding-bottom: 0;
box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);

@media screen and (max-width: 820px)
{
position: absolute;
p{
    font-size: 14px;
}
}
`
export const Wrap = styled.div`
background: #272727;
float: left;
color: #fff; 
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

text-align: center;
cursor: pointer;

h1{
    padding: 2rem;
    font-size: 1.5rem;
}

span{
    margin-right: 1.5rem;
}


@media screen and (max-width: 820px)
{
    h1{
        padding: 2rem;
        font-size: 1rem;
        text-align: left;
    }


}
`

export const Dropdown = styled.div`
background: #1c1c1c; 
float: left;
color: #00ffb9;
width: 100%;
height: 100px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-bottom: 1px solid #00ffb9;
border-top: 1px solid #00ffb9;
position: relative;
font-size: 18px;


@media screen and (max-width: 820px)
{
    font-size: 1rem;
    text-align: center;
}
`

