import React from 'react';
import { Container, FormWrap, Icon, FormContent, FormH1, Text } from './disclaimerelements'

const DisclaimerPage = () => {
    return (
        <>
          <Container>
          <FormWrap> 
          <Icon to="/">Verticality</Icon>
          <FormContent>
              <FormH1>Disclaimer</FormH1>
              <Text>The information provided on this website does not constitute investment advice, financial advice, trading advice, or any other sort of advice, and you should not treat any of the website's content as such. Verticality does not recommend that any cryptocurrency be bought, sold, or held by you. Do conduct your due diligence and consult your financial advisor before making any investment decisions.
Verticality will ensure the accuracy of the information listed on this website, although it will not hold any responsibility for any missing or wrong information. Verticality provides all information as is. You understand that you are using any and all information available here at your own risk.
The appearance of third-party advertisements and hyperlinks on Verticality does not constitute an endorsement, guarantee, warranty, or recommendation by Verticality. Do conduct your due diligence before deciding to use any third-party services.
Verticality may receive compensation for affiliate links. This compensation may be in the form of money or services and could exist without any action from a site visitor. Should you perform activities concerning an affiliate link, it is understood that some form of compensation might be made to Verticality. For example, if you click on an affiliate link and sign up and trade on an exchange, Verticality may receive compensation. </Text>
          </FormContent>
          </FormWrap>  
          </Container>  
        </>
    )
}

export default DisclaimerPage