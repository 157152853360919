import React, {useState} from 'react';
import Video from '../../videos/video.mp4';
import {Button} from '../buttonElements';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper,
    ArrowForward, ArrowRight} from './heroelements';


const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }
    return (
        <HeroContainer id='home'>
            <HeroBg>
                <VideoBg autoPlay loop muted playsInline="true" disablePictureInPicture="true" src={Video} type='video/mp4'/>
            </HeroBg>
            <HeroContent> 
                <HeroH1> Verticality </HeroH1>
                <HeroP> The New Crypto </HeroP>
                <HeroBtnWrapper>
                <Button to='start' smooth={true} duration={500} spy={true} onMouseEnter={onHover} onMouseLeave={onHover}
                primary="true"
                dark="true">
                Get Started {hover ? <ArrowForward /> : <ArrowRight/>}
                </Button>    
                </HeroBtnWrapper> 
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
