import Accordion from '../Accordion/accordion';

export const homeObjOne = {
    id: 'about', 
    lightBg: false,
    lightText: true, 
    lightTextDesc: true, 
    // topLine: 'A Crypto which is here to stay',
    headline: 'Buy Now or Regret Later',
    description: 'Verticality, or VRT, is a new cryptocurrency founded in 2022. Verticality is available on the popular exchange PancakeSwap. VRT will soon expand to more exchanges such as but not limited to Coinbase and Robinhood. ',
    buttonLabel: <td onClick={() => window.open("https://pancakeswap.finance/swap", "_blank")}>Purchase</td>, 
    imgStart: true,
    img: require('../../images/svg1.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'start', 
    lightBg: true,
    lightText: false, 
    lightTextDesc: false, 
    topLine: 'So, how do we get started?',
    // headline: 'Buy Now or Regret Later',
     description: 
    `Glad you asked! You must have MetaMask, PancakeSwap, and access to Binance Coin (BNB). All these websites are essential in purchasing VRTs. `,
    buttonLabel: <td onClick={() => window.open("https://docs.pancakeswap.finance/get-started", "_blank")}>Learn More</td>, 
    imgStart: true,
    img: require('../../images/svg2.svg').default,
    alt: 'checking off list  ',
    dark: false,
    primary: false,
    darkText: true
};


export const homeObjThree = {
    id: 'nft', 
    lightBg: false,
    lightText: true, 
    lightTextDesc: true, 
    topLine: 'NFTs are here to stay...',
    headline: 'AND SO ARE WE!',
    description: 'Verticality was founded on the fundamental basis of cryptocurrency. Verticality encompasses NFTs and crypto and strives to bring both. To ensure our promise, we strive to bring NFTs soon!  ',
    imgStart: true,
    img: require('../../images/svg3.svg').default,
    alt: 'Construction',
    dark: true,
    primary: false,
    darkText: false
};