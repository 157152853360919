import React from 'react'
import { FooterContainer, FooterWrap, FooterLinksContainer, FooterLinksWrapper, FooterLinkItems, FooterLinkTitle, FooterLink, FooterLinkA, SocialMediaWrap, WebsiteRights, SocialLogo } from './FooterElements'
const Footer = () => {
    return (
        <>
        <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle> Verticality </FooterLinkTitle>
                            <FooterLink to="/aboutUs"> About</FooterLink>
                            <FooterLink to='/disclaimer'> Disclaimer</FooterLink>
                            <FooterLinkA href='https://www.termsofservicegenerator.net/live.php?token=oPh4sa5sag0JkFmXGrOgGaAOrZGSdinW'  target='_blank'
          rel="noopener"> Terms of Service</FooterLinkA>
                             </FooterLinkItems>
                             <FooterLinkItems>
                                 <FooterLinkTitle>What We Use</FooterLinkTitle>
                            <FooterLinkA href='https://metamask.io/' target='_blank'
          rel="noopener"> MetaMask </FooterLinkA>
                            <FooterLinkA href="https://www.binance.us/en/home" target='_blank' rel='noopener'> Binance</FooterLinkA>
                            <FooterLinkA href='https://pancakeswap.finance/' target='_blank' rel='noopener'> PancakeSwap</FooterLinkA>
                        </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMediaWrap>
            <WebsiteRights>Verticality © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
            </SocialMediaWrap>
        </FooterWrap>    
        </FooterContainer>    
        </>
    )
}

export default Footer
